import React from "react";
import Slider from "react-slick";
import { NavHashLink } from "react-router-hash-link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../styles/homeSlider.css";

export default function HomeSlider() {
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
          ]
    };
    
    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/rayas.png" alt="Rayas"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">ALIMENTACIÓN DE RAYAS</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                            <div className="slider_element__t3">Ven y emociónate en esta divertida experiencia en el hábitat Bahía de Rayas. ¡Tienes que vivirlo!</div>
                            <NavHashLink to="/actividades#alimentacion_rayas" className="slider_element__btn">MAS INFORMACIÓN</NavHashLink>
                        </div>
                    </div>
                </div>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/pinguinos.png" alt="Pinguinos"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">INTERACCIÓN CON PINGÜINOS</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                            <div className="slider_element__t3">En esta increíble experiencia podrás verlos frente a ti, alimentarlos y tendrás la oportunidad de llevar una linda fotografía.</div>
                            <NavHashLink to="/actividades#interaccion_pinguinos" className="slider_element__btn">MAS INFORMACIÓN</NavHashLink>
                        </div>
                    </div>
                </div>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/agapornias.png" alt="Agapornias"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">ALIMENTACIÓN DE AGAPORNIAS</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                            <div className="slider_element__t3">Esta es una experiencia llena de colores que no te puedes perder. Actividad para toda la familia.</div>
                            <NavHashLink to="/actividades#alimentacion_agaponias" className="slider_element__btn">MAS INFORMACIÓN</NavHashLink>
                        </div>
                    </div>
                </div>
                    <div className="slider_element_container">
                        <div className="slider_element">
                            <img src="/images/home/contenedores/tour_vip.png" alt="Tour vip"/>
                            <div className="slider_element_des">
                                <div className="slider_element__t1">TOUR VIP</div>
                                <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                                <div className="slider_element__t3">¡Ponte el casco y acompáñanos en este recorrido especial! Aquí conocerás el corazón de Gran Acuario Mazatlán visitando el área de Soporte de Vida. </div>
                                <NavHashLink to="/actividades#tour_vip" className="slider_element__btn">MAS INFORMACIÓN</NavHashLink>
                            </div>
                        </div>
                    </div>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/snorkel.png" alt="Snorkel"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">EXPERIENCIA SNORKEL</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!</div>
                            <div className="slider_element__t3">Disfruta esta sorprendente experiencia donde podrás conocer todas las especies de nuestro Hábitat Oceánico acompañado de Buzos Profesionales.</div>
                            <a href="https://tickets.granacuario.com/mx/925-tickets/8758-experiencia-de-snorkel" target="_blank" className="slider_element__btn">COMPRAR</a>
                        </div>
                    </div>
                </div>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/buceo.png" alt="Buceo"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">EXPERIENCIA BUCEO</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!</div>
                            <div className="slider_element__t3">Si eres Buzo Certificado, esta experiencia es para ti. Sumérgete en nuestro Hábitat Oceánico con más de 2.6 millones de litros de agua y nada junto a Tiburones, Rayas, Meros y muchas más especies.</div>
                            <a href="https://tickets.granacuario.com/mx/925-tickets/8759-experiencia-de-buceo" target="_blank" className="slider_element__btn">COMPRAR</a>
                        </div>
                    </div>
                </div>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/lobos_marinos.png" alt="Lobos marinos"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">CONOCE A LOS LOBOS MARINOS</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>* Incluida en tu boleto "Visita Gran Acuario Mazatlan LYP".</div>
                            <div className="slider_element__t3">Déjate sorprender por su gran energía e increíbles habilidades. ¡Actividad para toda la familia!</div>
                            <NavHashLink to="/actividades#lobos_marinos" className="slider_element__btn">MAS INFORMACIÓN</NavHashLink>
                        </div>
                    </div>
                </div>
                <div className="slider_element_container">
                    <div className="slider_element">
                        <img src="/images/home/contenedores/habitat_pinguinos.png" alt="Habitat pinguinos"/>
                        <div className="slider_element_des">
                            <div className="slider_element__t1">HÁBITAT DE LOS PINGÜINOS</div>
                            <div className="slider_element__t2">¡Consulta nuestros horarios!<br/>* Incluida en tu boleto "Visita Gran Acuario Mazatlan LYP".</div>
                            <div className="slider_element__t3">Visita el hábitat de los pingüinos de Humboldt, las aves más tiernas y divertidas de Gran Acuario Mazatlán.</div>
                            <NavHashLink to="/actividades#interaccion_pinguinos" className="slider_element__btn">MAS INFORMACIÓN</NavHashLink>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}